@tailwind base;
@tailwind components;
@tailwind utilities;

@media (max-width: 768px) {

    /* ツールバー全体を縦並びに */
    .fc-toolbar {
        display: block !important;
    }

    /* ツールバー内の塊( left / center / right )を強制的に改行させる */
    .fc-toolbar-chunk {
        display: block !important;
        margin-bottom: 0.5rem;
        text-align: center;
        /* 中央寄せ */
    }

    .fc .fc-col-header-cell {
        font-size: 0.7rem;
        min-width: 40px;
    }
}